<template>
  <div class="app">
    <AppHeader v-if="d_showNavbar" fixed id="wisdom-header" style="background: #c6fcd8;">
      <b-navbar style="height: 50px; width: 100%;">
        <SidebarToggler class="d-flex d-lg-none d-xl-none" mobile />
        <b-link class="navbar-brand" :to="'/dashboard'" v-if="!device.isMobile">
          <img class="navbar-brand-full" src="img/brand/logo.svg" width="89" height="25" alt="WisdomEra">
          <img class="navbar-brand-minimized" src="img/brand/sygnet.svg" width="30" height="30" alt="WisdomEra">
        </b-link>
        <SidebarToggler class="d-none d-lg-flex d-xl-flex" />
        <b-navbar-nav class="d-none d-lg-flex d-xl-flex mr-auto">
          <!-- <b-nav-item class="px-2" to="/dashboard/search-patient" :exact="true"> -->
          <!-- <b-nav-item v-if="user" class="px-2" to="/dashboard" :exact="true">{{ $t('wdm16.4112') }}</b-nav-item> -->
          <!-- <b-nav-item class="px-3" to="/users" exact>Users</b-nav-item> -->
          <!-- <b-nav-item v-if="user" class="px-2" to="/settings/user" :exact="true">{{ $t('wdm16.4037') }}</b-nav-item> -->
          <a v-if="d_allFlowingText.list.length > 0" :href="'https://docs.wisdomera.io/#/documenttree?wdmr231=' + d_allFlowingText.list[d_allFlowingText.list_index].value.split('_')[1]" target="_blank" style="color: #50afc9; font-size: 16px; white-space: nowrap;">
              {{ d_allFlowingText.text.substring(d_allFlowingText.text.length - d_allFlowingText.index, d_allFlowingText.text.length) }}
          </a>
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto">
          <img src="@/icon/680848.png" class="header-icon" />
          <strong style="color: #33812c;"> <i>Wanalyzer</i> </strong>
          <strong v-if="w_presentation" style="color: red;">SUNUM MODU AÇIK</strong>
          <!-- <AppHeaderDropdown right no-caret v-if="user">
            <template slot="header">
              <img src="@/icon/1545359_red.png" class="header-icon" title="COVID-19" />
            </template>
            <template slot="dropdown">
              <b-dropdown-header tag="div" class="text-center"><strong>Hızlı erişim</strong></b-dropdown-header>
            </template>
          </AppHeaderDropdown> -->
          <b-nav-item id="help-info" v-if="!help.show && user" class="d-md-down-none" @click="store.commit('help_status', true)">
            <i class="fa fa-question" style="color: green;"></i>
          </b-nav-item>
          <b-nav-item v-if="help.show && user" id="help-info" v-else class="d-md-down-none" @click="store.commit('help_status', false)">
            <i class="fa fa-question" style="color: red;"></i>
          </b-nav-item>
          <b-popover v-if="user" target="help-info" triggers="hover focus" placement="bottom">
            <template slot="title"></template>
            <b-row>
              <b-col cols="12">Sayfalardaki yardım iconlarının görünmesini & kapanmasını sağlar</b-col>
            </b-row>
          </b-popover>
          <b-nav-item v-if="!user" class="d-md-down-none">
            <a href="https://fb.me/WisdomEra" target="_target" style="margin-right: 10px;"><i class="fa fa-facebook"> </i></a>
          </b-nav-item>
          <b-nav-item v-if="!user" class="d-md-down-none">
            <a href="https://twitter.com/WisdomEra" target="_target" style="margin-right: 10px;"><i class="fa fa-twitter"></i></a>
          </b-nav-item>
          <b-nav-item v-if="!user" class="d-md-down-none">
            <a href="https://instagram.com/wisdomera" target="_target" style="margin-right: 10px;"><i class="fa fa-instagram"></i></a>
          </b-nav-item>
          <b-nav-item v-if="!user" class="d-md-down-none">
            <a href="https://www.linkedin.com/company/wisdomera/" target="_target" style="margin-right: 10px;"><i class="fa fa-linkedin"></i></a>
          </b-nav-item>
          <!--         <b-nav-item class="d-md-down-none">
          <i class="icon-list"></i>
        </b-nav-item> -->
          <AppHeaderDropdown right no-caret v-if="user">
            <template slot="header">
              <i class="icon-magic-wand"></i>
            </template>
            <template slot="dropdown">
              <!--               <b-dropdown-header tag="div" class="text-center"><strong>Hızlı erişim</strong></b-dropdown-header>
              <b-dropdown-item @click="go_to_home()"><i class="fa fa-bell-o" /> Anasayfa
                <b-badge variant="info">&nbsp</b-badge>
              </b-dropdown-item>
              <b-dropdown-item v-if="user" @click="go_to_messenger_page()"><i class="fa fa-comments-o" /> Messenger & Meeting
                <b-badge variant="danger">&nbsp</b-badge>
              </b-dropdown-item>
              <b-dropdown-item v-if="!help.show" @click="store.commit('help_status', true)"><i class="fa fa-comments" /> Yardım iconlarını göster
                <b-badge variant="success">&nbsp</b-badge>
              </b-dropdown-item>
              <b-dropdown-item v-if="help.show" @click="store.commit('help_status', false)"><i class="fa fa-comments" /> Yardım iconlarını gösterme
                <b-badge variant="danger">&nbsp</b-badge>
              </b-dropdown-item>
              <b-dropdown-item v-if="user && user.permissions_result && user.permissions_result.indexOf('general_open-test-model') !== -1" @click="store.commit('TestMode')"><i class="fa fa-comments" /> Test Modu {{ StoreTest.mode ? ' (AÇIK) ' :' (KAPALI) ' }}
                <b-badge :variant="StoreTest.mode ? 'success' : 'danger'">&nbsp</b-badge>
              </b-dropdown-item>
              <b-dropdown-item v-if="user && user.permissions_result && user.permissions_result.indexOf('administrator') !== -1" @click="f_goToCustomerSupport()"><i class="fa fa-user" /> Müşteri Desteği
                <b-badge :variant="StoreTest.mode ? 'success' : 'danger'">&nbsp</b-badge>
              </b-dropdown-item> -->
              <b-dropdown-item @click="f_changePresentationMode()"><i class="fa fa-comments" /> Sunum Modu {{ w_presentation ? ' (AÇIK) ' :' (KAPALI) ' }}
                <b-badge :variant="w_presentation ? 'success' : 'danger'">&nbsp</b-badge>
              </b-dropdown-item>
            </template>
          </AppHeaderDropdown>
          <AppHeaderDropdown right no-caret v-if="user">
            <template slot="header">
              <i class="icon-people"></i>
            </template>
            <template slot="dropdown">
              <b-dropdown-header tag="div" class="text-center"><strong>Hesaplar</strong></b-dropdown-header>
              <b-dropdown-item v-for="(account, account_ind) in account_list" :key="'account_list_' + account_ind" @click="changeMultiAccount(account)">
                <i v-if="account.main_account === 1" class="fa fa-id-card-o"></i>
                <i v-else class="fa fa-user-o"></i>
                <template v-if="user && user.username === account.username">
                  <strong class="text-success">{{ account.name ? account.name : account.username }}</strong>
                </template>
                <template v-else>
                  {{ account.name ? account.name : account.username }}
                </template>
              </b-dropdown-item>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item v-if="account_list.length > 0 && account_list[0].special_permission && account_list[0].special_permission.indexOf('wisdom_login-to-users-without-password') !== -1" @click="changeAccountWithoutPasswordModal = true">Hesaplara Şifresiz Geçiş (özel yetki)</b-dropdown-item>
            </template>
          </AppHeaderDropdown>
          <AppHeaderDropdown right no-caret>
            <template slot="header">
              <i :class="['flag-icon', d_activeLanguage === 'en' ? 'flag-icon-us' : d_activeLanguage === 'fa' ? 'flag-icon-ir' : 'flag-icon-' + d_activeLanguage]" :title="d_activeLanguage" :id="d_activeLanguage"></i>
            </template>
            <template slot="dropdown">
              <template v-if="d_wisdomEraSystem && d_wisdomEraSystem.languages">
                <template v-for="(lang, lang_ind) in d_wisdomEraSystem.languages">
                  <b-dropdown-item @click="f_saveLanguageChange(lang.value)"><i :class="['flag-icon', lang.value === 'en' ? 'flag-icon-us' : lang.value === 'fa' ? 'flag-icon-ir' : 'flag-icon-' + lang.value]" :title="lang.value" :id="lang.value"></i>{{ lang.label }}
                  </b-dropdown-item>
                </template>
              </template>
            </template>
          </AppHeaderDropdown>
          <b-nav-item class="d-md-down-none" @click="d_showWisdomeraApps = true;" title="Uygulamalar">
            <img src="@/icon/2751062.png" style="width: 3em; padding: 0px;" />
          </b-nav-item>
          <DefaultHeaderDropdownAccnt />
        </b-navbar-nav>
        <!-- <AsideToggler class="d-none d-lg-block" /> -->
        <!--<AsideToggler class="d-lg-none" mobile />-->
      </b-navbar>
    </AppHeader>
    <div :class="d_showNavbar ? 'app-body' : ''" style="margin-bottom: 0px;">
      <AppSidebar fixed>
        <SidebarHeader />
        <SidebarForm />
        <SidebarNav :navItems="navItems"></SidebarNav>
        <SidebarFooter />
        <SidebarMinimizer />
      </AppSidebar>
      <main class="main" style="background-color: white; min-height: 750px;">
        <!-- <Breadcrumb :list="list" /> -->
        <div id="router-div">
          <router-view></router-view>
        </div>
      </main>
      <!--       <AppAside fixed>
        <DefaultAside />
      </AppAside> -->
    </div>
    <TheFooter v-if="d_showFooter" :fixed="true" style="align-items: initial; background: #2f353a; height: 30px;" id="wisdom-footer">
      <!--footer-->
      <div style="float: left;">
        <a href="http://wisdomera.io" style="color: #3cbdc2;">
          WisdomEra {{'v'}}
          <span v-if="st_version && st_version.data && st_version.data.version">
            {{ st_version.data.version }}
          </span> {{ 'OLK'}}
        </a>
        <span class="ml-1" style="color: #3cbdc2;">&copy; 2017.</span>
        <span style="color: #3cbdc2;">WisdomEra Inc. &nbsp</span>
      </div>
      <div style="float: left;">
        <wisdom-socket></wisdom-socket>
      </div>
      <div style="float: left;">
        <!-- <task-list></task-list> -->
      </div>
      <div class="ml-auto">
        <!--         <span class="mr-1">Powered by</span>
        <a href="https://wisdomera.io">WisdomEra</a> 
 -->
      </div>
    </TheFooter>
    <div v-if="d_showRightMenu && user" class="left-page-close-button">
      <b-button @click="d_showRightMenu = false" size="lg" style="padding: 0px;" variant="secondary"><img src="@/icon/1890926.png" width="30px;" /></b-button>
    </div>
    <div v-if="d_showRightMenu && user" class="left-page-fullscreen-button">
      <b-button variant="secondary" @click="f_goToAllTasksForAllOrganizations()" size="lg" style="padding: 0px;">
        <img src="@/icon/1568887.png" width="30px;" />
      </b-button>
    </div>
    <div class="wisdom-right-menu" v-if="d_showRightMenu && user" @mouseleave="">
      <task-list :right_menu="true"></task-list>
    </div>
    <b-modal id="d_showChangeMyHospitalModal" v-model="d_showChangeMyHospitalModal" title="Hastanemi Değiştir (özel yetki)" centered header-bg-variant="info" header-text-variant="dark">
      <b-row>
        <b-col cols="6">
          <b-form-select :plain="true" v-model="d_changeHospitalData.hospital_group" @change="f_changeHospitalGroup()" required>
            <option v-for="hospital_groups in d_hospitalGroupList" :value="hospital_groups.value">{{ hospital_groups.label }}</option>
          </b-form-select>
        </b-col>
        <b-col cols="6">
          <b-form-select :plain="true" v-model="d_changeHospitalData.hospital_id" required>
            <option v-for="hospitals in d_hospitalList" :value="hospitals.value">{{ hospitals.label }}</option>
          </b-form-select>
        </b-col>
      </b-row>
      <template slot="modal-footer">
        <button :disabled="!d_changeHospitalData.hospital_group || !d_changeHospitalData.hospital_id" type="button" class="btn btn-success" @click="f_changeMyHospital()">Hastanemi Değiştir</button>
        <button type="button" class="btn btn-danger" @click="d_showChangeMyHospitalModal = false">{{ $t('wdm16.4053') }}</button>
      </template>
    </b-modal>
    <modal v-if="help.no" @close="store.commit('help_no', '')" :width="'1500'">
      <h3 slot="header"> Yardım</h3>
      <div slot="body">
        <help :help_no="help.no"></help>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-danger" @click="store.commit('help_no', '')">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <b-modal id="store-loading" v-model="ShowLoadingModal" no-close-on-esc no-close-on-backdrop centered hide-footer hide-header-close body-bg-variant="dark" style="border-radius: 30px!important; z-index: 9999; box-shadow: -2px -2px;">
      <b-row v-if="StoreLoading.data.label" style="vertical-align: middle; text-align: center;">
        <b-col sm="12" lg="3">
          <!-- <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner> -->
          <!-- <img src="../../static/img/gif/right-left.gif" class="img-rounded img-responsive" style="width: 30px; height: 30px;" /> -->
          <img src="../../static/img/gif/wisieyesmile.gif" class="img-rounded img-responsive" style="width: 100px; height: 100px;" />
        </b-col>
        <b-col sm="12" lg="9">
          <div style="padding-top: 5px;">{{ StoreLoading.data.label }}</div>
          <template v-if="d_progressData">
            <b-progress :value="d_progressData.percent" variant="success" show-progress striped :animated="true" class="mb-2"></b-progress>
            <div style="padding-top: 5px;">
              <span v-if="d_progressData.total_process_count"> Toplam İşlem: {{ d_progressData.total_process_count }} </span>
              <span v-if="d_progressData.process_count">İşlem Adedi: {{ d_progressData.process_count }} </span>
            </div>
            <div v-if="d_progressData.message" style="padding-top: 5px;"> Mesaj: {{ d_progressData.message }} </div>
          </template>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal id="store-modal" v-model="show_store_modal" :title="StoreModal.data.title ? StoreModal.data.title : 'Uyarı'" :centered="StoreModal.data.centered ? StoreModal.data.centered : true" :header-bg-variant="StoreModal.data.header_bg_variant ? StoreModal.data.header_bg_variant : 'info'" :header-text-variant="StoreModal.data.header_text_variant ? StoreModal.data.header_text_variant : 'dark'" :size="StoreModal.data.size ? StoreModal.data.size : 'md'">
      <b-row :style="StoreModal.data.style ? StoreModal.data.style : 'vertical-align: middle; text-align: center;'">
        <b-col sm="12" lg="3">
          <!-- <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner> -->
          <!-- <img src="../../static/img/gif/right-left.gif" class="img-rounded img-responsive" style="width: 30px; height: 30px;" /> -->
          <img src="../../static/img/gif/wisieyesmile.gif" class="img-rounded img-responsive" style="width: 100px; height: 100px;" />
        </b-col>
        <b-col sm="12" lg="9">
          <div style="white-space: pre-line;" v-if="StoreModal.data.text">{{ StoreModal.data.text }}</div>
          <div v-if="StoreModal.data.text_html" v-html="StoreModal.data.text_html"></div>
        </b-col>
      </b-row>
      <template slot="modal-footer">
        <b-button v-if="StoreModal.data.type === 'alert'" @click="show_store_modal = false">{{ $t('wdm16.11212') }}</b-button>
        <b-button v-if="StoreModal.data.type === 'confirm'" @click="show_store_modal = false">{{ $t('wdm16.3975') }}</b-button>
        <b-button v-if="StoreModal.data.type === 'confirm'" @click="ConfirmStoreModal()">İşlemi gerçekleştir</b-button>
      </template>
    </b-modal>
    <modal v-if="changeAccountWithoutPasswordModal" @close="changeAccountWithoutPasswordModal = false" large="True">
      <h3 slot="header">
        Hesaplara Şifresiz Geçiş (özel yetki)
      </h3>
      <div slot="body">
        <user-list-for-wisdom :main_account_username="account_list[0].username"></user-list-for-wisdom>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-danger" @click="changeAccountWithoutPasswordModal = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <b-modal id="d_showAgreementModal.show" v-model="d_showAgreementModal.show" title="Sözleşme ve Lisans Kabul İşlemleri" centered hide-footer no-close-on-esc centered no-stacking no-close-on-backdrop hide-header-close header-bg-variant="info" header-text-variant="dark">
      <agreement-modal :p_showThisModal="d_showAgreementModal" :p_missingAgreement="d_missingAgreement"></agreement-modal>
    </b-modal>
    <b-modal v-if="d_showWisdomeraApps" v-model="d_showWisdomeraApps" size="sm" title="Uygulamalar" centered hide-footer body-bg-variant="secondary" header-bg-variant="secondary">
      <wisdomera-apps></wisdomera-apps>
    </b-modal>
  </div>
</template>

<script>
import {
  default as UserListForWisdom
} from '@/components/widgets/UserListForWisdom';
import {
  default as WisdomeraApps
} from '@/components/widgets/WisdomeraApps';
import UserService from '@/services/users';
import WdmService from '@/services/wdm';
import { DateFunctions } from '@/services/public/date_functions';
import moment from "moment";
import auth from '@/auth';
import {
  default as AgreementModal
} from "@/components/widgets/AgreementModal";
import {
  default as Help
} from '@/components/widgets/Help';
import HospitalService from '@/services/hospital';
import _nav from '@/_nav';
import { HeaderDropdown as AppHeaderDropdown } from '@coreui/vue'
import { SidebarToggler, Sidebar as AppSidebar, SidebarFooter, SidebarForm, SidebarHeader, SidebarMinimizer, SidebarNav } from '@/components/Sidebar'
import { Header as AppHeader, Aside as AppAside, AsideToggler, Footer as TheFooter, Breadcrumb } from '@coreui/vue'
import DefaultAside from './DefaultAside'
import DefaultHeaderDropdownAccnt from './DefaultHeaderDropdownAccnt'
import { mapGetters } from 'vuex';
import {
  default as Modal
} from '@/components/widgets/Modal';
import {
  default as WisdomSocket
} from '@/components/socket/index';
import store from '@/store';
import WisdomDataService from "@/services/wisdom_data";

export default {
  name: 'DefaultContainer',
  components: {
    AgreementModal,
    AppAside,
    AppHeader,
    AppHeaderDropdown,
    AppSidebar,
    AsideToggler,
    Breadcrumb,
    DefaultAside,
    DefaultHeaderDropdownAccnt,
    Help,
    Modal,
    SidebarFooter,
    SidebarForm,
    SidebarHeader,
    SidebarMinimizer,
    SidebarNav,
    SidebarToggler,
    TheFooter,
    WisdomSocket,
    UserListForWisdom,
    WisdomeraApps
  },
  computed: {
    ...mapGetters({
      sidebar: 'sidebar',
      device: 'device',
      help: 'help',
      StoreModal: 'modal',
      StoreTest: 'test',
      messenger_data: 'messenger_data',
      StoreLoading: 'loading',
      account_list: 'account_list',
      StoreUser: 'user',
      st_version: 'version'
    }),
    name () {
      return this.$route.name
    },
    list () {
      return this.$route.matched.filter((route) => route.name || route.meta.value)
    }
  },
  notifications: {
    showNotification: { // You can have any name you want instead of 'showNotification'
      title: '',
      message: '',
      type: '' // You also can use 'VueNotifications.types.error' instead of 'error'
    }
  },
  created: function () {
    // console.log('this.$router : ', this.$route);
    if (['rowanalyzebyid', 'reportmoduleiframe'].indexOf(this.$route.name) !== -1) {
      this.d_showNavbar = false;
      this.d_showSideBar = false;
      this.d_showFooter = false;
    }
    this.w_presentation = JSON.parse(localStorage.getItem('presentation'));
    this.user = JSON.parse(localStorage.getItem('user'));
    this.d_wisdomEraSystem = JSON.parse(localStorage.getItem('wisdomera_system'));
    if (JSON.parse(localStorage.getItem('account_list'))) {
      this.$store.commit('account_list', JSON.parse(localStorage.getItem('account_list')));
    }
    this.f_activeLanguage();
    this.f_getHeaderTreeList();
  },
  beforeCreate: function () {},
  destroyed () {
    clearInterval(this.d_flowingTextInterval);
    // clearInterval(this.d_nextDrawAlgorithm);
  },
  mounted: function () {
    _nav.f_updateItems();
    this.navItems = _nav.items;
    auth.f_getWdm16IdMeaningWithLangId(this.$i18n, 'default');
    if (this.StoreLoading.status) {
      this.ShowLoadingModal = true;
    }
    if (this.$options.sockets) {
      this.$options.sockets.onmessage = function (message) {
        let socket_data = JSON.parse(message.data);
        if (socket_data.type && ['show_convert_progress'].indexOf(socket_data.type) !== -1) {
          if (this.showProgresBarInterval) {
            // clearInterval(this.showProgresBarInterval);
            this.showProgresBarInterval = 0;
          }
          this.d_progressData = socket_data.data;
          // console.log(this.d_progressData.process_count);
          this.$forceUpdate();
          // this.showNotification({ title: 'Karar Destek Sistemi', message: rule_message, type: 'success', timeout: 5000 });
          // this.run_rule(socket_data);
          // console.log(socket_data);
        }
      }
    }
    if (this.d_wisdomEraSystem && this.d_wisdomEraSystem.agreement) {
      this.f_checkAgreements();
    }
  },
  data () {
    return {
      d_showFooter: true,
      d_showNavbar: true,
      d_showWisdomeraApps: false,
      d_showSideBar: true,
      w_presentation: false,
      d_progressData: '',
      d_showWisdomEraSupport: false,
      d_showMessenger: false,
      DateFunctions: DateFunctions,
      changeAccountWithoutPasswordModal: false,
      d_showChangeMyHospitalModal: false,
      ShowLoadingModal: false,
      show_store_modal: false,
      store: store,
      navItems: _nav.items,
      user: {},
      d_expandAllClosedCollapse: true,
      filterTable: '',
      d_showRightMenu: false,
      d_showMyProtocols: false,
      d_hospitalGroupList: [],
      d_hospitalList: [],
      d_changeHospitalData: { 'hospital_group': '', 'hospital_id': '' },
      d_wisdomEraSystem: {},
      d_showAgreementModal: { 'show': false, 'type': '' },
      d_missingAgreement: [],
      d_activeLanguage: '',
      d_headerTreeList: {
        'list': [],
        'parent': {
          'key': 'wdm232_19',
          'type': 'wdm232',
          'bucket': 'wisdom'
        },
        'child': {
          'reference_wdm_key': 'wdm_wisdom_wdm231_v2.0',
          'type': 'wdm231',
          'bucket': 'wisdom',
          'other_possible_relations': []
        }
      },
      d_allFlowingText: {
        'list': [],
        'list_index': 0,
        'text': '',
        'index': 0
      },
      d_flowingTextInterval: 0
    }
  },
  methods: {
    f_getHeaderTreeList: function () {
      let data = {
        'parent_wdm': this.d_headerTreeList.parent,
        'child_wdm': this.d_headerTreeList.child,
        'relation_type': 'keys',
        'type': 'get_wdmr_data', // only_relation_list or get_wdmr_data
        'layer_type': 'first' // first or all
      };
      WdmService.get_wdmr_tree_list_remote(data)
        .then(resp => {
          if (resp.data.status === "success") {
            if (resp.data.result.status === 'success') {
              this.d_headerTreeList.list = resp.data.result.result.list;
              // this.f_prepareExplanationListForFlow();
              this.f_prepareNameOfWdm231ForFlow();
              this.f_startFlowingText();
            }
          } else {
            console.log('errorr get_wdmr_tree_list_remote : ', resp.data.message);
          }
        });
    },
    f_prepareNameOfWdm231ForFlow: function () {
      for (let i in this.d_headerTreeList.list) {
        let x = this.d_headerTreeList.list[i];
        let flow_text = { 'label': '                        ' + x.label, 'value': x.key };
        this.d_allFlowingText.list.push(flow_text);
      }
    },
    f_prepareExplanationListForFlow: function () {
      for (let i in this.d_headerTreeList.list) {
        let x = this.d_headerTreeList.list[i];
        let flow_text = { 'label': '', 'value': x.key };
        if (x.wdmr.data && x.wdmr.data.general && x.wdmr.data.general.info && x.wdmr.data.general.info.list && x.wdmr.data.general.info.list.length > 0) {
          for (let k in x.wdmr.data.general.info.list) {
            let info_data = x.wdmr.data.general.info.list[i];
            if (info_data.explanation && info_data.explanation.val) {
              flow_text.label += info_data.explanation.val;
            }
          }
        }
        this.d_allFlowingText.list.push(flow_text);
      }
    },
    f_startFlowingText: function () {
      // when we start, we defined the list index we are flowing on.
      if (this.d_allFlowingText.list.length > 0) {
        this.d_allFlowingText.text = this.d_allFlowingText.list[0].label;
        this.d_allFlowingText.list_index = 0;
        this.d_flowingTextInterval = setInterval(function () {
          if (this.d_allFlowingText.index !== this.d_allFlowingText.text.length) {
            this.d_allFlowingText.index += 1;
          } else {
            this.d_allFlowingText.index = 0;
            if (this.d_allFlowingText.list_index !== this.d_allFlowingText.list.length - 1) {
              this.d_allFlowingText.list_index += 1;
              this.d_allFlowingText.text = this.d_allFlowingText.list[this.d_allFlowingText.list_index].label;
            } else {
              this.d_allFlowingText.list_index = 0;
              this.d_allFlowingText.text = this.d_allFlowingText.list[0].label;
            }
          }
        }.bind(this), 100);
      }
    },
    f_activeLanguage: function () {
      if (this.user && this.user.active_lang) {
        this.f_saveLanguageChange(this.user.active_lang, true);
      } else {
        if (this.d_wisdomEraSystem && this.d_wisdomEraSystem.active_lang && this.d_wisdomEraSystem.active_lang.value) {
          this.f_saveLanguageChange(this.d_wisdomEraSystem.active_lang.value);
        } else if (localStorage.getItem('active_lang')) {
          this.f_saveLanguageChange(localStorage.getItem('active_lang'));
        }
      }
    },
    f_saveLanguageChange: function (new_language, dont_change = false) {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': this.$t('wdm16.10990') } });
      if (this.user) {
        if (dont_change) {
          localStorage.setItem('active_lang', new_language);
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          this.$store.commit('lang', new_language);
          this.d_activeLanguage = new_language;
        } else {
          UserService.save_language_change({ 'active_lang': new_language })
            .then(resp => {
              if (resp.data.status === 'success') {
                this.$store.commit('Loading', { 'status': false, 'data': {} });
                this.user.active_lang = new_language;
                localStorage.setItem('user', JSON.stringify(this.user));
                localStorage.setItem('active_lang', new_language);
                auth.f_getWdm16IdMeaningWithLangId(this.$i18n, 'this', new_language);
                this.$store.commit('lang', new_language);
                this.d_activeLanguage = new_language;
              } else {
                alert('error' + resp.data.message);
              }
            });
        }
      } else {
        auth.f_getWdm16IdMeaningWithLangId(this.$i18n, 'this', new_language);
        this.$store.commit('lang', new_language);
        localStorage.setItem('active_lang', new_language);
        this.d_activeLanguage = new_language;
        this.$store.commit('Loading', { 'status': false, 'data': {} });
      }
    },
    f_checkAgreements: function () {
      this.d_missingAgreement = [];
      if (this.user) {
        if (!this.user.agreement) {
          for (let i in this.d_wisdomEraSystem.agreement) {
            this.d_missingAgreement.push(i);
          }
        } else {
          for (let i in this.d_wisdomEraSystem.agreement) {
            if (!this.user.agreement[i]) {
              this.d_missingAgreement.push(i);
            } else if (this.user.agreement[i] && this.user.agreement[i].list.length === 0) {
              this.d_missingAgreement.push(i);
            } else if (this.user.agreement[i].list[this.user.agreement[i].list.length - 1].agreement.version !== this.d_wisdomEraSystem.agreement[i].list[this.d_wisdomEraSystem.agreement[i].list.length - 1].version) {
              this.d_missingAgreement.push(i);
            }
          }
        }
        if (this.d_missingAgreement.length > 0) {
          this.d_showAgreementModal.type = 'user';
          this.d_showAgreementModal.show = true;
        }
      } else {
        if (!localStorage.getItem('agreement')) {
          for (let i in this.d_wisdomEraSystem.agreement) {
            this.d_missingAgreement.push(i);
          }
        } else {
          let local_agreement = JSON.parse(localStorage.getItem('agreement'));
          for (let i in this.d_wisdomEraSystem.agreement) {
            if (!local_agreement[i]) {
              this.d_missingAgreement.push(i);
            } else if (local_agreement[i] && local_agreement[i].list.length === 0) {
              this.d_missingAgreement.push(i);
            } else if (local_agreement[i].list[local_agreement[i].list.length - 1].agreement.version !== this.d_wisdomEraSystem.agreement[i].list[this.d_wisdomEraSystem.agreement[i].list.length - 1].version) {
              this.d_missingAgreement.push(i);
            }
          }
        }
        if (this.d_missingAgreement.length > 0) {
          this.d_showAgreementModal.type = 'guest';
          // this.d_showAgreementModal.show = true;
        }
      }
    },
    f_changeMyHospital: function () {
      let data = {};
      data.username = this.user.username;
      data.hospital_group = this.d_changeHospitalData.hospital_group;
      data.hospital_id = this.d_changeHospitalData.hospital_id;
      UserService.change_my_hospital(data)
        .then(resp => {
          if (resp.data.status === 'success') {
            this.d_showChangeMyHospitalModal = false;
            let account = this.account_list[0];
            let account_login_data = {
              'username': account.username,
              'password': account.password,
              'multi_account_password': 1,
              'not_control_password': 0
            };
            // this.$router.push('/settings/user');
            auth.login(this, account_login_data, '/dashboard');
          }
        });
    },
    f_changeHospitalGroup: function () {
      for (let i in this.d_hospitalGroupList) {
        if (this.d_hospitalGroupList[i].value === this.d_changeHospitalData.hospital_group) {
          this.d_hospitalList = JSON.parse(JSON.stringify(this.d_hospitalGroupList[i].hospital_list));
          this.$forceUpdate();
          break;
        }
      }
    },
    f_getHospitalGroup: function () {
      HospitalService.get_all_hospitals_list()
        .then(resp => {
          if (resp.data.status === 'success') {
            this.d_hospitalGroupList = JSON.parse(JSON.stringify(resp.data.result));
            this.d_showChangeMyHospitalModal = true;
            this.f_changeHospitalGroup();
          }
        });
    },
    f_showChangeMyHospitalModal: function () {
      this.d_changeHospitalData.hospital_group = this.user.hospital_group;
      this.d_changeHospitalData.hospital_id = this.user.hospital_id;
      if (this.d_hospitalGroupList.length === 0) {
        this.f_getHospitalGroup();
      } else {
        this.d_showChangeMyHospitalModal = true;
      }
    },
    f_changePresentationMode: function () {
      if (this.w_presentation) {
        this.w_presentation = false;
        localStorage.setItem('presentation', false);
      } else {
        this.w_presentation = true;
        localStorage.setItem('presentation', true);
      }
    },
    f_goToCustomerSupport: function () {
      this.$router.push({ path: '/whsmodules/administrator/customer-support' });
    },
    f_goToAllTasksForAllOrganizations: function () {
      this.$router.push({ path: '/whsmodules/organization-task' });
      this.d_showRightMenu = false;
    },
    f_controlNewMessages: function () {
      for (let i in this.messenger_data) {
        for (let d in this.messenger_data[i]['day_list']) {
          if (this.messenger_data[i]['day_list'][d].new) {
            return true;
          }
        }
      }
      return false;
    },
    f_calculateLabTestClass: function (test) {
      if (test.val) {
        if (test.clb !== undefined && test.clb !== '' && test.clb !== null) {
          if (test.val < test.clb) {
            return 'clb-value';
          }
        }
        if (test.cub !== undefined && test.cub !== '' && test.cub !== null) {
          if (test.val > test.cub) {
            return 'cub-value';
          }
        }
        if (test.ub !== undefined && test.ub !== '' && test.ub !== null) {
          if (test.val > test.ub) {
            return 'ub-value';
          }
        }
        if (test.lb !== undefined && test.lb !== '' && test.lb !== null) {
          if (test.val < test.lb) {
            return 'lb-value';
          }
        }
        return '';
      }
    },
    DateFormat: function (date) {
      if (date) {
        if (date.indexOf('T') === -1) {
          return moment(date, "YYYY-MM-DD hh:mm:ss").format("DD.MM.YYYY");
        } else {
          return moment(date, "YYYY-MM-DDThh:mm:ss").format("DD.MM.YYYY HH:mm");
        }
      } else {
        return "";
      }
    },
    f_addDefaultTextToHims: function () {
      for (let protocol_no in this.hims_protocol_list) {
        if (this.hims_protocol_list[protocol_no].send_status) {
          for (let p in this.WebserviceProtocolInsuranceData['MLPCARE']['parameter_order']) {
            let param = this.WebserviceProtocolInsuranceData['MLPCARE']['parameter_order'][p];
            let param_data = this.WebserviceProtocolInsuranceData['MLPCARE']['parameter_list'][param];
            if (param_data['required'] && !this.hims_protocol_list[protocol_no][param] && param_data.default !== 'user_will_fill') {
              if (param_data.default === 'today') {
                let today = new Date();
                this.hims_protocol_list[protocol_no][param] = moment(today).toISOString();
              } else {
                this.hims_protocol_list[protocol_no][param] = param_data.default;
              }
            }
          }
        }
      }
      this.d_showHimsProtocols = false;
      this.f_saveHimsDataContinue();
    },
    changeMultiAccount: function (account) {
      let account_login_data = {
        'username': account.username,
        'password': account.password,
        'multi_account_password': 1,
        'not_control_password': 0
      };
      // this.$router.push('/settings/user');
      auth.login(this, account_login_data, '/dashboard').then(resp => {
        _nav.f_updateItems();
        this.navItems = _nav.items;
        location.reload();
      }, resp => {
        _nav.f_updateItems();
        this.navItems = _nav.items;
        location.reload();
      });
    },
    ConfirmStoreModal: function () {
      let data = JSON.parse(JSON.stringify(this.StoreModal.data));
      data.confirm = true;
      this.$store.commit('modal', data);
      this.show_store_modal = false;
    },
    go_to_home: function () {
      if (this.user) {
        this.$router.push({ path: '/', query: {} });
      } else {
        this.$router.push({ path: '/web/dashboard', query: {} });
      }
    },
    go_to_messenger_page: function () {
      this.$router.push({ path: '/whsmodules/messenger', query: {} });
    },
    d_showSideBarTrueFalse: function () {
      this.d_showSideBar = false;
      setTimeout(function () {
        this.d_showSideBar = true;
      }.bind(this), 500);
    },
    f_showProgressBarByInterval: function () {
      this.showProgresBarInterval = setInterval(() => {
        if (!this.d_progressData) {
          this.d_progressData = {};
        }
        if (this.d_progressData.percent === undefined) {
          this.d_progressData.percent = 5;
        } else {
          if (this.d_progressData.percent <= 50) {
            this.d_progressData.percent += 4;
          } else if (this.d_progressData.percent > 50 && this.d_progressData.percent <= 75) {
            this.d_progressData.percent += 3;
          } else if (this.d_progressData.percent > 75 && this.d_progressData.percent <= 85) {
            this.d_progressData.percent += 2;
          } else if (this.d_progressData.percent > 85 && this.d_progressData.percent < 99) {
            this.d_progressData.percent += 1;
          }
        }
        this.$forceUpdate();
      }, 500);
    }
  },
  watch: {
    'StoreUser': function () {
      this.user = JSON.parse(localStorage.getItem('user'));
      let user_send = { 'username': this.user['username'], 'hospital_group': this.user['hospital_group'], 'hospital_id': this.user['hospital_id'], 'department': this.user['department'] };
      this.$socket.send(JSON.stringify({ 'type': 'user_change', 'user': user_send }));
      this.changeAccountWithoutPasswordModal = false;
      auth.last_wisdomera_version_control();
      this.$forceUpdate();
    },
    'show_store_modal': function () {
      if (!this.show_store_modal) {
        this.$store.commit('modal_show', false);
      }
    },
    'StoreLoading.status': function () {
      if (this.StoreLoading.status) {
        this.ShowLoadingModal = true;
        this.d_progressData = '';
        this.f_showProgressBarByInterval();
      } else {
        this.ShowLoadingModal = false;
        this.d_progressData = '';
        clearInterval(this.showProgresBarInterval);
      }
    },
    'StoreModal.show': function () {
      if (this.StoreModal.show) {
        this.show_store_modal = true;
      } else {
        this.show_store_modal = false;
        if (this.StoreModal.data.function_name) {
          if (this.StoreModal.data.function_name === 'f_addDefaultTextToHims' && this.StoreModal.data.confirm) {
            this.f_addDefaultTextToHims();
          }
        }
      }
    }
  }
}

</script>

<style type="text/css">
.clb-value {
  color: red;
  font-weight: bold;
}

.cub-value {
  color: red;
  font-weight: bold;
}

.lb-value {
  color: red;
  font-weight: bold;
}

.ub-value {
  color: red;
  font-weight: bold;
}

.right-footer-icon {
  width: 2em;
  cursor: pointer;
  float: right;
  margin-right: 5px;
}

.right-footer-icon:hover {
  width: 2.2em;
  cursor: pointer;
}

.header-icon {
  width: 2.5em;
  cursor: pointer;
  float: right;
}

.header-icon:hover {
  width: 3em;
  cursor: pointer;
}

.left-page-close-button {
  position: fixed;
  right: 0px;
  top: 55px;
  z-index: 1101;
}

.left-page-fullscreen-button {
  position: fixed;
  right: 35px;
  top: 55px;
  z-index: 1101;
}

</style>

